/**
 * Created by jahansj on 05/09/2016.
 */
define('jThumbs', ['app', 'jZoom'], app => {
  
  /** jThumbs */
  class jThumbs {
    constructor() {
      this.main = document.querySelector('.product-img:not(.product-img-thumb)');
      this.thumbContainer = document.querySelector('.product-thumbnails');
      this.thumbs = document.querySelectorAll('.product-thumb-box');
      this.jZoomImage = document.querySelector('[data-j-zoom-main="true"]');
      this.jZoomOverlay = document.querySelector('.jZoom_overlay');
      this.variations = document.querySelectorAll('.variation-dropdowns select');
      this.openIcon = document.querySelector('.jZoom_open-icon');
      this.openLink = document.querySelector('.jZoom_button-open');
      this.callCount = 0;
      const firstThumb = this.thumbs[0].querySelector('.product-img');

      app.element.addClass('jZoom_selected', firstThumb);
      this.testInitialImage()
        .then((val) => {
          this.jZoomImage.src = val.magnify;
          app.element.removeClass('jZoom_button-superhide', this.openLink);
          app.element.removeClass('jZoom-superhide', this.openIcon);
          app.element.removeClass('jZoom_overlay-superhide', this.jZoomOverlay);
        })
        .catch((err) => {
          app.element.addClass('jZoom_button-superhide', this.openLink);
          app.element.addClass('jZoom-superhide', this.openIcon);
          app.element.addClass('jZoom_overlay-superhide', this.jZoomOverlay);

          console.warn(err.error || err);
        });
      this.addEvents();
    }

    /**
     * Add events to thumbnails and variations.
     */
    addEvents() {
      this.thumbContainer.addEventListener('click', e => this.switchImage(e));

    /*
    todo - image variations come in a later release
    for (let i = 0, length = this.variations.length; i < length; i++) {
      this.variations[i].addEventListener('change', () => {
        this.variationsHandler();
      });
    }
    */
    }

    /**
     * Test to see if an image has a 1600x1600 variant.
     * @param link
     * @returns {Promise}
     */
    testImage(link) {
      const xhr = new XMLHttpRequest();
      let imgSrc;

      if (!link) {
        imgSrc = document.querySelector('.jZoom_ul img'); // first Image
      } else {
        imgSrc = link;
      }

      const imageLink = imgSrc.replace('70/70', '1600/1600');

      xhr.open('GET', encodeURI(imageLink));

      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          const res = xhr.response;

          if (!res || xhr.status !== 200) {
            return reject('jZoom: Hidden: Could not find large image variant.');
          }

          resolve(imageLink);
        };

        xhr.send();
      });
    }

    /**
     * See if an image has a 1600x1600 image variant without querying the CDN
     * @param thumbnail
     * @returns {Promise}
     */
    testInitialImage(thumbnail) {
      let imageIndex;
      // If no slide is passed get the first image

      if (!thumbnail) {
        imageIndex = 0;
      } else {

        imageIndex = thumbnail.getAttribute('data-index');

        if (!imageIndex) {

          if (thumbnail.alt) {
            const alt = thumbnail.alt.split('');
            imageIndex = parseInt(alt[alt.length - 1]) - 1;
          } else {
            imageIndex = -1;
          }
        }
      }
      const imageLinks = document.querySelector('.jZoom_imageLinks .jZoom_magnify');
      const usingXlImage = document.querySelector('.jZoom_imageLinks').getAttribute('data-using-xl-image');
      const imageEl = imageLinks.querySelector(`[data-j-zoom-image-link-${imageIndex}]`);
      const link = imageEl.getAttribute('data-image-link');
      const thumbnailImageSet = document.querySelector('.jZoom_imageLinks .jZoom_thumbnails');
      const thumbnailImageEl = thumbnailImageSet.querySelector(`[data-j-zoom-image-link-${imageIndex}]`);
      const thumbnailLink = thumbnailImageEl.getAttribute('data-image-link');

      return new Promise((resolve, reject) => {
        if (imageIndex < 0) {
          return reject({
            thumbnail: `${window.siteObj.productImagePrefix}${thumbnailLink}`,
            error: 'jZoom: Hidden: Could not find large image variant.'
          });
        }

        let imageSuffix;

        if (usingXlImage) {
          imageSuffix = document.querySelector(`.jZoom_imageLinks .jZoom_extraLarge [data-j-zoom-image-link-${imageIndex}]`).getAttribute('data-image-link');
        } else {
          imageSuffix = document.querySelector(`.jZoom_imageLinks .jZoom_carousel [data-j-zoom-image-link-${imageIndex}]`).getAttribute('data-image-link');
        }

        this.main.src = `${window.siteObj.productImagePrefix}${imageSuffix}`;

        if (link) {
          return resolve({
            magnify: `${window.siteObj.productImagePrefix}${link}`,
            thumbnail: `${window.siteObj.productImagePrefix}${thumbnailLink}`
          });
        } else {
          reject({
            thumbnail: `${window.siteObj.productImagePrefix}${thumbnailLink}`,
            error: 'jZoom: Hidden: Could not find large image variant.'
          });
        }
      });
    }

    /**
     * Switch the main product image and jZoom image.
     * todo - switch out URLs for siteObj.productImagePrefix?
     * @param e
     */

    switchImage(e) {
      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();

      const lastThumb = document.querySelector('.jZoom_selected');
      if (lastThumb) {
        app.element.removeClass('jZoom_selected', lastThumb);
      }

      app.element.addClass('jZoom_selected', e.target);

      this.testInitialImage(e.target)
        .then((val) => {

          app.element.removeClass('jZoom_button-superhide', this.openLink);
          app.element.removeClass('jZoom-superhide', this.openIcon);
          app.element.removeClass('jZoom_overlay-superhide', this.jZoomOverlay);
          this.jZoomImage.src = val.magnify;
        })
        .catch((err) => {
          app.element.addClass('jZoom_button-superhide', this.openLink);
          app.element.addClass('jZoom-superhide', this.openIcon);
          app.element.addClass('jZoom_overlay-superhide', this.jZoomOverlay);
          console.warn(err.error);
        });
    }

    /**
     * Call variations to get image variations.
     * @returns {Promise}
     */
    callVariations() {
      const SKU = window.siteObj.productID;
      const URL = window.siteObj.siteNonSecureURL;
      const xhr = new XMLHttpRequest();

      xhr.open('GET', encodeURI(`${URL}variations.json?productId=${SKU}`));

      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          const res = xhr.response;

          if (!res || xhr.status !== 200) {
            return reject('jZoom: Error: Could not find new image variations.');
          }

          resolve(JSON.parse(res));
        };

        xhr.send();
      });
    }

    /**
     * Wrapper around callVariations.
     */
    variationsHandler() {
      this.callVariations()
        .then((val) => {
          const thumbnails = document.querySelectorAll('.jZoom_ul li');
          const variations = this.hasVariations(val);

          if (!variations) {
            throw 'jZoom: Error: Could not find image variations';
          }

          for (let i = 0, length = variations.length; i < length; i++) {
            const CDN = this.getRandom();
            const thumb = this.thumbify(variations[i].cdn);

            thumbnails[i].querySelector('img').src = `http://s${CDN}.thcdn.com/${thumb}`;
          }
        })
        .catch((err) => {
          this.callCount++;

          if (this.callCount < 4) {
            this.variationsHandler();
          } else {
            console.warn(err);
          }
        });
    }

    /**
     * Take a CDN link and return the thumbnail variant.
     * todo - remove and append entire thumbnail CDN link to CDN URL
     * todo - this will come as part of the image variations update
     * @param cdn
     * @returns {string}
     */
    thumbify(cdn) {
      const noSlash = cdn.split('/');

      return `${noSlash[0]}/70/70/${noSlash[3]}`;
    }

    /**
     * Find 1600x1600 image variations.
     * @param res
     * @returns {*}
     */
    hasVariations(res) {
      if (!res.images) {
        return false;
      }

      let imageMap = [];
      let exists = [];

      for (let i = 0, length = res.images.length; i < length; i++) {
        const image = res.images[i];

        if (image.type === 'magnify') {
          exists.push(image.index);
          imageMap.push({
            index: image.index,
            cdn: image.name
          });
        }
      }

      if (imageMap.length) {
        return imageMap;
      } else {
        return false;
      }
    }

    /**
     * Find which size image the main product image is.
     * @returns {*}
     */
    getMainImgSize() {
      const mainImage = document.querySelector('[data-j-zoom-main-image] img');

      // For testing purposes
      if (!mainImage) {
        return '480/480';
      }

      const CDN = mainImage.src;

      if (CDN.indexOf('480/480') !== -1) {
        return '480/480';
      }

      if (CDN.indexOf('600/600') !== -1) {
        return '600/600';
      }
    }

    /**
     * Get a random number between 1 and 4.
     * @returns {number}
     */
    getRandom() {
      const min = 1;
      const max = 5;

      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  return jThumbs;
});
